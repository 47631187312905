import { Link } from "gatsby"
import React from "react"

const _ = require("lodash")

const TagSmall = ({ name }) => {
  return (
    <Link
      to={`/tags/${_.kebabCase(name)}`}
      className="flex flex-row items-center mr-4 my-1"
    >
      <div className="bg-grey-100 rounded-full text-grey-800 py-1 px-2 text-sm">
        {name}
      </div>
    </Link>
  )
}

export default TagSmall
