import React from "react"
import { graphql } from "gatsby"
import BlikiBrief from "../components/BlikiBrief"
import Layout from "../components/Layout"

export const pageQuery = graphql`
  query($tag: String) {
    allFile(
      limit: 2000
      sort: { fields: [childMdx___frontmatter___last_updated_on], order: DESC }
      filter: {
        childMdx: {
          fields: { show: { eq: true } }
          frontmatter: { tags: { in: [$tag] }, type: { eq: "note" } }
        }
      }
    ) {
      totalCount
      edges {
        node {
          extension
          childMdx {
            frontmatter {
              title
              tags
              last_updated_on(formatString: "MMM DD, YYYY")
              slug
              type
            }
          }
        }
      }
    }
  }
`

const tagTemplate = ({ data, pageContext }) => {
  const { tag } = pageContext
  const blikis = data.allFile.edges

  return (
    <Layout>
      <div className="container sm:max-w-screen-sm mx-auto mt-10 pt-24 w-11/12 sm:w-9/12">
        <h1 className="font-bold">{`Tag: "${tag}"`}</h1>
        <hr className="w-1/6 my-4 border-none h-2 text-primary-400 bg-primary-400 rounded" />
        <div>
          {blikis.map(({ node }, index) => {
            return (
              <BlikiBrief
                key={index}
                title={node.childMdx.frontmatter.title}
                slug={node.childMdx.frontmatter.slug}
                tags={node.childMdx.frontmatter.tags}
                type={node.childMdx.frontmatter.type}
                last_updated_on={node.childMdx.frontmatter.last_updated_on}
              />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default tagTemplate
