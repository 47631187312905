import { Link } from "gatsby"
import React from "react"
import Tag from "./Tag"

const BlikiBrief = ({ title, slug, tags, type, last_updated_on }) => {
  return (
    <div className="container flex flex-col my-8 px-4 sm:px-0">
      <div className="flex flex-row items-center mr-4 my-1">
        <button className="py-0.5 px-4 rounded-full bg-primary-500 text-gray-100 text-xs btn-primary uppercase">
          {type}
        </button>

        <button className="py-0.5 px-4 rounded-full bg-white font-semibold text-gray-100 text-xs btn-primary uppercase">
          <span className="text-grey-700">{last_updated_on}</span>
        </button>
      </div>

      <Link to={`/${slug}`}>
        <h3 className="text-primary-500 text-2xl">{title}</h3>
      </Link>
      <div className="flex flex-row flex-wrap mt-1">
        {tags &&
          tags.map((tag, index) => {
            return <Tag key={index} name={tag} />
          })}
      </div>
    </div>
  )
}

export default BlikiBrief
