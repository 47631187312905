import React, { useState } from "react"
import { Link } from "gatsby"
import links from "../constants/links"
import { FaAlignRight } from "react-icons/fa"
import classNames from "../util/Helper"

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  return (
    <header>
      <nav className="fixed top-0 right-0 left-0 z-50 border-t-4 border-t-secondary-400 bg-grey-50">
        <div className="lg:flex lg:flex-row lg:justify-between lg:items-center mx-2 lg:mx-12 xl:mx-16 2xl:mx-20">
          <div className="flex flex-row justify-between items-center p-4">
            <Link to="/">
              <img src="/logo.png" className="w-auto h-12" alt="logo" />
            </Link>
            <Link to="/">
              <span className="small-caps font-header sm:text-2xl text-md mx-10">Ambitious Systems</span>
            </Link>
            <button
              type="button"
              className="bg-transparent border-none outline-none hover:cursor-pointer lg:hidden"
              onClick={toggleNav}
              aria-label="Menu"
            >
              <FaAlignRight className="text-primary-500 text-2xl" />
            </button>
          </div>
          <ul
            className={classNames(
              "list-none overflow-hidden lg:h-auto lg:flex lg:flex-row",
              isOpen ? "h-30" : "h-0"
            )}
          >
            {links.map((item, index) => {
              return (
                <li key={index}>
                  <Link
                    to={item.path}
                    className="block py-4 pr-0 pl-12 no-underline font-header font-bold hover:text-primary-400"
                    activeClassName="text-primary-500"
                  >
                    <span className="small-caps">{item.text}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Navbar
