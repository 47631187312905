import React from "react"

const Footer = () => {
  return (
    <footer className="bg-grey-50 mt-12 py-12">
      <div className="max-w-screen-lg text-center mx-auto">
        <div className="leading-2 text-sm text-grey-700">
          &copy; {new Date().getFullYear()} Ambitious Systems. All Rights
          Reserved.
        </div>
      </div>
    </footer>
  )
}

export default Footer
