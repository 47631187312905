const links = [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/garden",
    text: "Garden",
  },
  {
    path: "/about",
    text: "About",
  },
];

export default links;