import React from "react"
import Container from "./Container"
import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <Container>{children}</Container>
      <Footer />
    </div>
  )
}

export default Layout
