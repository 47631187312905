import React from "react"

const Container = ({ children }) => {
  return (
    <section className="flex flex-col flex-1 border-t-2 border-grey-100">
      {children}
    </section>
  )
}

export default Container
